.modal {
  &__container {
    position: fixed;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 20px;
    box-sizing: border-box;
    max-width: 520px;
    background: #fff;
    /* soft-shadow */
    box-shadow: 0px 8px 32px 0px rgba(52, 58, 64, 0.08);
  }
  &_title {
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    color: #000;
    margin-bottom: 30px;
  }
}

.item {
  display: flex;
  margin-bottom: 12px;
  svg {
    width: 30px;
    height: 30px;
    margin-right: 12px;
    stroke: #0073ae;
    fill: #0073ae;
  }
  .text {
    font-weight: 700;
    font-size: 18px;
    color: #000;
    margin-bottom: 4px;
  }
  .subtext {
    font-weight: 400;
    font-size: 16px;
    color: #000;
  }
  &.mobile {
    display: none;
  }
}
.question {
  width: 100%;
  text-align: center;
  margin-bottom: 14px;
  span {
    font-weight: 700;
    font-size: 18px;
    color: #000;
  }
  a {
    color: #000;
  }
}
.checkbox__block {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.checkbox {
  display: flex;
  width: 30px;
  padding: 4px;
  background-color: #c8c8c8;
  background-color: #0073ae;
  cursor: pointer;
  border-radius: 14px;
  margin-right: 6px;
  &_text {
    font-weight: 400;
    font-size: 16px;
    color: #000;
  }

  &.active {
    background-color: #3cb371;
    flex-direction: row-reverse;
  }
}
.inner {
  display: flex;
  border-radius: 50%;
  background-color: #fff;
  height: 14px;
  width: 14px;
}
.btn {
  transition: all .2s;
  border-radius: 6px;
  background-color: #0073ae;
  color: #ffffff;
  margin-top: 20px;
  padding: 10px 0px;
  text-align: center;
  cursor: pointer;
  display: block;
  width: 100%;
  border: none;

  &:hover {
  background-color: #036697;

  }
}
@media (max-width: 992px) {
  .modal_title {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .modal__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 14px 10px;
  }
  .text {
    font-size: 16px !important;
  }
  .subtext {
    font-size: 14px !important;
  }
  .content {
    width: calc(100% - 32px);
  }
  .btn {
    margin-top: 10px;
  }
  .checkbox_text {
    font-size: 14px !important;
  }
  .item {
    display: none;

    svg {
      width: 20px;
      height: 20px;
    }
    &.mobile {
      display: flex;
    }
  }
}

@media (max-width: 600px) {
  .modal__container {
    top: 40px;
    left: 0;
    transform: unset;
    width: 100%;
    max-width: unset;
  }
}