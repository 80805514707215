.modal {
  &_bg {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: auto;
    background: rgba(254, 254, 253, 0.8);
    backdrop-filter: blur(4px);
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100) !important;
  }
}
