.App {
  /* text-align: center; */
}

[role="presentation"] {
  z-index: 999;
}

@media (max-width: 992px) {
  #player {
    height: 100vh !important; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100) !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 800px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  font-size: 21px;
  justify-content: center;
  flex-direction: column;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
